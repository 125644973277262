import { Auth0Options } from '@faroconnect/auth0-frontend';
import { AuthzType } from '@faroconnect/authz-client';
import { FaroConfigLp } from './types/config';

type WebshareRegion = AuthzType.WebshareRegion;

export const MOCKED_DEFAULT_DOMAIN = 'faro';
export const BASE_URL = '/home';
export const BASE_API_URL = `${BASE_URL}/v1`;

export const BASE_AUTHZ_URL = '/authz';
export const BASE_AUTHZ_API_URL = `${BASE_AUTHZ_URL}/v1`;

const audience = 'farosphere-api';

// For sensitive operations, force the user to re-login for more security.
// The backend determines when this is necessary.
// It was instead implemented in redirectAfterAuthenticationError(), but could be useful later.
// const forceLogin = location.search.match(/[?&]forceLogin=true\b/);
// const prompt = forceLogin ? 'login' : undefined;

const oidcLocalhost: Auth0Options = {
	domain: 'login.eu.dev.farosphere.com',
	clientId: 'TBVaUsbNnHRxt6j1KZCSVEQJiwRQMNFO',
	audience,
	// prompt, // If enabling it again, also enable it for the other environments.
};

const oidcDev: Auth0Options = {
	domain: 'login.eu.dev.farosphere.com',
	clientId: 'KBygi7sREPoE6KHQY58bPJKsahVcVZB6',
	audience,
};

const oidcStg: Auth0Options = {
	domain: 'login.eu.stg.farosphere.com',
	clientId: 'KkDGLxrxvLL9AnHFpGytGGnt6v65EjvF',
	audience,
};

const oidcPrd: Auth0Options = {
	domain: 'login.eu.farosphere.com',
	clientId: 'u3aqaZnjxpvnAdfeBqvIgAmZEGNUDH54',
	audience,
};

/** Environment: 'local' (localhost), or 'dev', 'stg', 'prd' (AWS). */
let env: 'local' | 'dev' | 'stg' | 'prd';
// Environment used when loading the configuration file.
let configFileEnv: typeof env;
let oidc: Auth0Options;
/** API endpoint to call routes in the landing page backend. */
let apiEndpoint: string;
/** API endpoint to call routes in the authz backend. */
let authzApiEndpoint: string;
/** API endpoint to call routes in the subscription service backend. */
let subscriptionApiEndpoint: string;
/** API endpoint to call routes in the project service backend. */
let projectServiceEnpoint: string;
/** API endpoint to call routes in the sphere analytics service. */
let sphereAnalyticsApiEndpoint: string;
/** WebShare root domains, without URL scheme or subdomain. */
let webShareEU: string | undefined;
let webShareUS: string | undefined;
let webShareRegions: WebshareRegion[] = ['eu', 'us'];
/* Protocol for webshare service. Defaults to https In local environments http protocol is required. */
let webShareProtocol: 'https' | 'http' = 'https';
/** Enterprise SSO Config frontend and API. */
let ssoConfigFront: string = location.origin + '/sso-config/';
let ssoConfigApi: string;
let authzUI: string;
let landingpageUI: string;
let workspaceDashboardLocalUI: string = 'http://localhost:4812/workspacedashboard';
let workspaceDashboardUI: string;
let holobuilder: string;
let redirectEndpoint: Record<AuthzType.WebshareRegion, string | undefined>;
const redirectLocalhost = 'http://localhost:4813/migrator/v1/redirect';
// Allow to disable the XG redirect for a certain time, e.g. for debugging.
// It's best to store this in localStorage for some minutes, because the Auth0 login page is opened by redirect.
// The localStorage item is shared between LP/AuthZ.
let allowRedirect = true;
try {
	if (location.search.includes('redirect=true')) {
		// Flag allowRedirect is already true; remember for next page load.
		localStorage.removeItem('noRedirectUntil');
	} else if (location.search.includes('redirect=false')) {
		allowRedirect = false;
		const endDate = new Date();
		endDate.setUTCMinutes(endDate.getUTCMinutes() + 5);
		localStorage.setItem('noRedirectUntil', endDate.toISOString());
	} else {
		const endDateStr = localStorage.getItem('noRedirectUntil');
		if (endDateStr && new Date() < new Date(endDateStr)) {
			allowRedirect = false;
		}
	}
} catch (e) {
	console.error(e);
}

// Note the difference between location.host (with port, if nonstandard) and location.hostname (without port).
if (location.hostname === 'localhost' && process?.env?.VUE_APP_FAROENV === 'dev') {
	// Convenient UI development on localhost against the APIs in the AWS dev environment.
	env = 'dev';
	configFileEnv = 'local'; // Even though the services are the ones in dev, the configuration file used is the one stored locally.
	oidc = oidcLocalhost;
	apiEndpoint = 'https://landingpage.api.eu.dev.farosphere.com';
	authzApiEndpoint = 'https://authz.api.eu.dev.farosphere.com';
	subscriptionApiEndpoint = 'https://subscription.api.eu.dev.farosphere.com';
	projectServiceEnpoint = 'https://projectservice.api.eu.dev.farosphere.com';
	sphereAnalyticsApiEndpoint = 'https://sphere-analytics.api.eu.dev.farosphere.com';
	webShareEU = undefined; // WebShare DEV currently runs in the US only.
	webShareUS = 'dev.webshare-america.net';
	webShareRegions = ['us'];
	ssoConfigApi = 'https://sso-config.api.eu.dev.farosphere.com';
	authzUI = 'https://www.dev.farosphere.com/authz';
	landingpageUI = 'https://www.dev.farosphere.com/home';
	workspaceDashboardUI = workspaceDashboardLocalUI;
	holobuilder = 'https://workspace.dev.holobuilder.com/server-selection';
	redirectEndpoint = { eu: undefined, us: 'https://redirect-service.dev.holobuilder.eu/api/redirect' };
	console.log('Override environment: AWS dev');
} else if (location.host === 'localhost') {
	env = 'local';
	configFileEnv = env;
	oidc = oidcLocalhost;
	// Developer VM, accessing the frontend through proxy on port 80.
	// For convenience, we also use port 80 to access the backends in this case, without CORS.
	apiEndpoint = 'http://localhost';
	authzApiEndpoint = 'http://localhost';
	subscriptionApiEndpoint = 'http://localhost';
	projectServiceEnpoint = 'http://localhost';
	sphereAnalyticsApiEndpoint = 'http://localhost';
	webShareEU = 'localhost';
	webShareUS = 'localhost';
	webShareRegions = ['eu', 'us'];
	webShareProtocol = 'http';
	ssoConfigApi = 'http://localhost';
	authzUI = 'http://localhost/authz';
	landingpageUI = 'http://localhost/home';
	workspaceDashboardUI = 'http://localhost/workspacedashboard';
	holobuilder = 'https://workspace.dev.holobuilder.com/server-selection';
	redirectEndpoint = { eu: redirectLocalhost, us: redirectLocalhost };
} else if (location.hostname === 'localhost') {
	env = 'local';
	configFileEnv = env;
	oidc = oidcLocalhost;
	// Accessing the frontend and backend directly, with CORS.
	// This is mainly to check that CORS in the cloud deployment will work.
	apiEndpoint = 'http://localhost:4803';
	authzApiEndpoint = 'http://localhost:4800';
	subscriptionApiEndpoint = 'http://localhost:6789';
	projectServiceEnpoint = 'http://localhost:4809';
	sphereAnalyticsApiEndpoint = 'http://localhost:5001';
	// See ApplicationWebShare.ts > makeSsoUrl, makeDataHubSsoUrl: We'll adapt the port when opening the WebShare/DataHub UI.
	// We can use the Vue Dev Server proxy for API calls, to work around that WebShare on localhost did not support CORS.
	// Nowadays it does support it, so we can use 'local.faroconnect.org' to enable subdomain support.
	// If it doesn't work for you, try changing it to 'local-ipv6.faroconnect.org' or location.host.
	// webShareEU = location.host;
	// webShareEU = 'local-ipv6.faroconnect.org';
	webShareEU = 'local.faroconnect.org';
	webShareUS = webShareEU;
	webShareRegions = ['eu', 'us'];
	webShareProtocol = 'http';
	ssoConfigFront = 'http://localhost:4808';
	ssoConfigApi = 'http://localhost:4807';
	authzUI = 'http://localhost:4802/authz';
	landingpageUI = 'http://localhost:4804/home';
	workspaceDashboardUI = workspaceDashboardLocalUI;
	holobuilder = 'https://workspace.dev.holobuilder.com/server-selection';
	redirectEndpoint = { eu: redirectLocalhost, us: redirectLocalhost };
} else if (location.hostname.endsWith('dev.farosphere.com')) {
	// AWS dev environment.
	env = 'dev';
	configFileEnv = env;
	oidc = oidcDev;
	apiEndpoint = 'https://landingpage.api.eu.dev.farosphere.com';
	authzApiEndpoint = 'https://authz.api.eu.dev.farosphere.com';
	subscriptionApiEndpoint = 'https://subscription.api.eu.dev.farosphere.com';
	projectServiceEnpoint = 'https://projectservice.api.eu.dev.farosphere.com';
	sphereAnalyticsApiEndpoint = 'https://sphere-analytics.api.eu.dev.farosphere.com';
	webShareEU = undefined; // WebShare DEV currently runs in the US only.
	webShareUS = 'dev.webshare-america.net';
	webShareRegions = ['us'];
	ssoConfigApi = 'https://sso-config.api.eu.dev.farosphere.com';
	authzUI = 'https://www.dev.farosphere.com/authz';
	landingpageUI = 'https://www.dev.farosphere.com/home';
	// For numbered dev environments, make sure to use the same host.
	workspaceDashboardUI = location.origin + '/workspacedashboard';
	holobuilder = 'https://workspace.dev.holobuilder.com/server-selection';
	redirectEndpoint = { eu: undefined, us: 'https://redirect-service.dev.holobuilder.eu/api/redirect' };
} else if (location.hostname.endsWith('stg.farosphere.com')) {
	// AWS staging environment.
	env = 'stg';
	configFileEnv = env;
	oidc = oidcStg;
	apiEndpoint = 'https://landingpage.api.eu.stg.farosphere.com';
	authzApiEndpoint = 'https://authz.api.eu.stg.farosphere.com';
	subscriptionApiEndpoint = 'https://subscription.api.eu.stg.farosphere.com';
	projectServiceEnpoint = 'https://projectservice.api.eu.stg.farosphere.com';
	sphereAnalyticsApiEndpoint = 'https://sphere-analytics.api.eu.stg.farosphere.com';
	webShareEU = 'websharecloud.net';
	webShareUS = 'webshare-america.net';
	webShareRegions = ['eu', 'us'];
	ssoConfigApi = 'https://sso-config.api.eu.stg.farosphere.com';
	authzUI = 'https://www.stg.farosphere.com/authz';
	landingpageUI = 'https://www.stg.farosphere.com/home';
	workspaceDashboardUI = location.origin + '/workspacedashboard';
	holobuilder = 'https://workspace.staging.holobuilder.com/server-selection';
	redirectEndpoint = { eu: 'https://redirect-service.staging.holobuilder.eu/api/redirect', us: 'https://redirect-service.staging.holobuilder.eu/api/redirect' };
} else {
	// Default: AWS prod environment.
	env = 'prd';
	configFileEnv = env;
	oidc = oidcPrd;
	apiEndpoint = 'https://landingpage.api.eu.farosphere.com';
	authzApiEndpoint = 'https://authz.api.eu.farosphere.com';
	subscriptionApiEndpoint = 'https://subscription.api.eu.farosphere.com';
	projectServiceEnpoint = 'https://projectservice.api.eu.farosphere.com';
	sphereAnalyticsApiEndpoint = 'https://sphere-analytics.api.eu.farosphere.com';
	webShareEU = 'websharecloud.com';
	webShareUS = 'webshare-america.com';
	webShareRegions = ['eu', 'us'];
	ssoConfigApi = 'https://sso-config.api.eu.farosphere.com';
	authzUI = 'https://www.farosphere.com/authz';
	landingpageUI = 'https://www.farosphere.com/home';
	workspaceDashboardUI = location.origin + '/workspacedashboard';
	holobuilder = 'https://workspace.holobuilder.com/server-selection';
	redirectEndpoint = { eu: 'https://redirect-service.holobuilder.eu/api/redirect', us: 'https://redirect-service.holobuilder.eu/api/redirect' };
}

// The Migrator is currently deployed in the same regions as WebShare and DataHub.
const migratorApiEndpoints: Record<WebshareRegion, string|undefined> = {
	eu: !webShareEU ? undefined : (webShareEU.startsWith('local') ? 'http://localhost:4813' : `https://${webShareEU}`),
	us: !webShareUS ? undefined : (webShareUS.startsWith('local') ? 'http://localhost:4813' : `https://${webShareUS}`),
};

export const config = {
	env,
	oidc,
	apiEndpoint,
	authzApiEndpoint,
	subscriptionApiEndpoint,
	projectServiceEnpoint,
	sphereAnalyticsApiEndpoint,
	webShareEU,
	webShareUS,
	webShareRegions,
	webShareProtocol,
	ssoConfigFront,
	ssoConfigApi,
	authzUI,
	landingpageUI,
	workspaceDashboardUI,
	holobuilder,
	migratorApiEndpoints,
	redirectEndpoint,
	allowRedirect,
};

export const dynamicConfig: FaroConfigLp = window.FARO_CONFIG_LP ?? {
	// This case should not happen since we should have access to the file, but in that case use a wrong value
	// to make sure it is asserted as wrongly loaded file.
	env: 'Error: window.FARO_CONFIG_LP is undefined!' as any,
	featureFlag: {},
	url: {},
	img: {},
};

// Do not use $assert.Assert because we don't want this assertion to be removed in production.
console.assert(configFileEnv === dynamicConfig.env, `Loaded wrong configuration file. Expected ${configFileEnv}, found ${dynamicConfig.env}`);

// ---------------------------------------------------------------------------------------------------------
// Temporary state for in-progress operations which are interrupted by a redirect (e.g. to the login page).
// ---------------------------------------------------------------------------------------------------------

const INIT_CHANGE_EMAIL_KEY = 'fc$LANDINGPAGE$init-change-email';

function getChangeEmailNext(): boolean {
	try {
		// I tried using faroLocalStorage, but getItem() always returned null after coming back from the login?!
		const enabled = localStorage.getItem(INIT_CHANGE_EMAIL_KEY) === 'true';
		// The dialog should be shown only once, so remove the flag after reading it.
		localStorage.removeItem(INIT_CHANGE_EMAIL_KEY);
		return enabled;
	} catch (error) {
		console.error(error);
		return false;
	}
}
export function setChangeEmailNext(): void {
	try {
		localStorage.setItem(INIT_CHANGE_EMAIL_KEY, 'true');
	} catch (error) {
		console.error(error);
	}
}

export const tempState = {
	changeEmailNext: getChangeEmailNext(),
};
