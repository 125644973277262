
import Bottleneck from 'bottleneck';
import Vue from 'vue';
import Component from 'vue-class-component';
import { $assert, WebUtils } from '@faroconnect/utils';
import { HttpClient } from '@faroconnect/clientbase';
import { FaroSimpleIconButton, FaroComponentButton, FaroIconButtonI, FaroDividerButton } from '@faroconnect/baseui';
import { AuthzType } from '@faroconnect/authz-client';
import { WebshareRegion } from '@faroconnect/authz-client/public/definitions/AuthzType';
import TopPageBase from '@/components/PageBase/TopPageBase.vue';
import { AnyState, DurationInfo, Migration, ProjectExtended } from '@/classes/migrator/Migration';
import { StatsAggregatedI, StatsAggregatedMap, WorkspaceStats } from '@/classes/migrator/WorkspaceStats';
import { EmailType, Workspace } from '@/classes/authz/Workspace';
import { isFeatureEnabledByAuth0PermissionsSync } from '@/utils/permissions';
import { getErrorMessage } from '@/utils/errorhandler';
import { config } from '@/config';
import { BaseServiceAny } from '@/store/services/BaseServiceAny';
import { downloadBufferAsFile } from '@/utils/browser';
import { sortObject } from '@/utils/sortitems';
import { AnalyticsService } from '@/store/services/AnalyticsService';

/**
 * Interface for info object about occurring migration states.
 *  - state: Migration state.
 *  - index: Position in the UI (0 = top).
 *  - count: Number of migrations of this state.
 */
interface MigrationStateEntry {
	state: string;
	index: number;
	count: number;
}

/**
 * Needs to be a class, not an interface, to support sorting the State column with the StateCaption getter.
 * @author OK
 */
class MigrationItem {
	/**
	 * Workspace UUID.
	 */
	public UUID: string;
	/**
	 * Workspace.
	 */
	public Workspace: Workspace;
	/**
	 * Group number/name, e.g. '5'.
	 */
	public Group: string | null;
	/**
	 * Group number/name, e.g. '05'.
	 */
	public GroupSortable: string | null;
	/**
	 * Estimated duration in seconds.
	 */
	public EstDurationSec: number | undefined;
	/**
	 * Estimated duration string in short form, e.g. "2d 5h 1m".
	 */
	public EstDurationStr: string | undefined;
	/**
	 * The number of projects in the workspace.
	 */
	public NumProjects: number | undefined;
	/**
	 * The last update date of a project in the workspace.
	 */
	public LastUpdate: string | undefined;
	/**
	 * The migrations sorted from new to old.
	 */
	public Migrations?: Migration[];
	/**
	 * The newest migration provided in the Migrations array.
	 */
	public Migration?: Migration;
	/**
	 * Info objects about occurring migration states with the aggregated states sorted by the last occurrence date.
	 */
	public stateInfoArray?: MigrationStateEntry[];
	/**
	 * Set to true if a running migration has failed tasks that did not yet get retried successfully.
	 * This is used to show a warning icon in the UI.
	 */
	public hasRunningMigrationWithErrors: boolean;
	/**
	 * MAX(this.Migrations[*].UpdateDate), for change detection when polling.
	 */
	public latestUpdateDate: string | undefined;

	/**
	 * @param workspace Workspace.
	 * @param migrations The migrations sorted from new to old.
	 * @param stateInfoArray Info objects about occurring migration states with the aggregated states sorted by the
	 *        last occurrence date.
	 */
	public constructor(workspace: Workspace, group: string | null, migrations?: Migration[], stateInfoArray?: MigrationStateEntry[]) {
		this.UUID = workspace.UUID;
		this.Workspace = workspace;
		this.Group = group;
		this.GroupSortable = group ? (group.length > 1 ? '' : '0') + group : null;
		this.EstDurationSec = undefined;
		this.EstDurationStr = undefined;
		this.Migrations = migrations;
		this.Migration = migrations ? migrations[0] : undefined;
		this.stateInfoArray = stateInfoArray;
		this.hasRunningMigrationWithErrors = false;
		this.latestUpdateDate = undefined;
		this.NumProjects = undefined;
		this.LastUpdate = undefined;
	}

	/**
	 * Returns the caption to use for the item's state, using the newest migrations.
	 * @author OK
	 */
	public get StateCaption(): string {
		return this.Migration ? this.Migration.getCaption() : 'Ready';
	}

	/**
	 * Returns the caption for the item's states, using all migrations.
	 * @author OK
	 */
	public get StateCaptions(): string[] {
		const captions: string[] = [];
		for (const migration of this.Migrations || []) {
			const caption = migration.getCaption();
			captions.push(caption);
		}
		return captions;
	}

	public get WorkspaceDisplayName(): string {
		return (!this.Workspace.WebshareDomainName || this.Workspace.Name === this.Workspace.WebshareDomainName) ?
			this.Workspace.Name :
			`${this.Workspace.Name} (${this.Workspace.WebshareDomainName})`;
	}

	public get nameCssClass(): string {
		if (this.Migration?.DataDeleted) {
			return 'data-deleted';
		} else if (this.Workspace?.BaseSelected) {
			return 'base-selected';
		} else {
			return '';
		}
	}

	public get nameTitleTag(): string {
		if (this.Migration?.DataDeleted) {
			return 'WebShare S3 data is deleted';
		} else if (this.Workspace?.BaseSelected) {
			return 'Sphere Base workspace selected for upgrade by workspace owner';
		} else {
			return '';
		}
	}
}

interface FaroIconButtonExtendedMigrationItem extends FaroSimpleIconButton {
	getDisabled?: (entity: MigrationItem) => boolean;
	getDisabledTooltip?: (entity: MigrationItem) => string;
	getHidden?: (entity: MigrationItem) => boolean;
	getCustomComponent?: (entity: MigrationItem) => FaroComponentButton;
}

interface TaskResult {
	total: number;
	complete: number;
	error: number;
}

/**
 * The Migration Dashboard showing the migration status of all migratable workspaces in the environment.
 */
@Component({
	components: {
		TopPageBase,
	},
})
export default class MigrationDashboardPage extends Vue {
	protected pollingInterval: ReturnType<typeof setInterval> | null = null;
	public isPolling: boolean = false;
	public error: boolean = false;
	public errorMsg: string | null = null;
	public loading: boolean = false;
	/**
	 * Flag if there are displayed workspaces in the EU region.
	 */
	public hasInEU: boolean = config.webShareRegions.includes('eu');
	/**
	 * Flag if there are displayed workspaces in the US region.
	 */
	public hasInUS: boolean = config.webShareRegions.includes('us');
	/**
	 * The request to the Subscription Service to get all workspaces takes several seconds, so we want to cache the
	 * result. Interesting workspaces rarely get created or deleted so it's okay to keep them for the entire browser
	 * session.
	 */
	public workspaces?: Workspace[] = undefined;
	/**
	 * Items to display in the table.
	 */
	public items: MigrationItem[] = [];
	/**
	 * Map from Workspace UUID to item.
	 */
	public itemsMap: Record<string, MigrationItem> = {};
	/**
	 * SSO data for all workspaces.
	 */
	public ssoData?: any;
	/**
	 * All group IDs.
	 */
	public groups: string[] = [];
	/**
	 * The selected group ID to get the estimated durations for.
	 */
	public groupForEstDurations: string = '';
	/**
	 * False: Limit dropdown menu to 10 migrations per workspace (+ pending/running ones).
	 * True:  Unlimited migrations per workspace.
	 */
	public showAllMigrations = false;
	/**
	 * Show all table columns.
	 */
	public showAllCols = false;
	/**
	 * Flag if the dialog to select the projects to migrate is currently visible.
	 */
	public showSelectProjectsDlg: boolean = false;
	/**
	 * Flag if the dialog for single workspace stats is currently visible.
	 */
	public showWorkspaceStatsDlg: boolean = false;
	/**
	 * The workspace statistics object displayed in the dialog box for the selected workspace.
	 */
	public statsObj: WorkspaceStats | null = null;
	/**
	 * Set of workspaces which have statistics available.
	 */
	public workspacesWithStats: Record<string, true> | null = null;
	/**
	 * Projects that can be migrated as returned by the Migrator's project route. Needed for the list of projects
	 * shown in the project selection dialog.
	 */
	public projectsExtended: ProjectExtended[] = [];
	/**
	 * Only the projectsExtended that match the project search.
	 */
	public projectsExtendedFiltered: ProjectExtended[] = [];
	/**
	 * The migration item that the user selected for migration.
	 */
	public selectedItem?: MigrationItem = undefined;
	/**
	 * Flag if the checkbox for "All Projects" in the project selection dialog is checked.
	 */
	public selectAllProjects: boolean = true;
	/**
	 * Flag to show list of projects.
	 */
	public showProjects: boolean = false;
	/**
	 * Array of project UUIDs for all projects selected in the project selection dialog.
	 * Only use if selectAllProjects is set to false.
	 */
	public selectedProjects: string[] = [];
	/**
	 * Number of currently running migrations.
	 */
	public nRunning: number = 0;
	/**
	 * Total number of projects in currently running migrations.
	 */
	public nProjectsTotal: number = 0;
	/**
	 * Number of successfully migrated projects in currently running migrations.
	 */
	public nProjectsComplete: number = 0;
	/**
	 * Number of failed projects in currently running migrations.
	 */
	public nProjectsError: number = 0;
	/**
	 * Map with stats about the tasks in currently running migrations.
	 * The key is the task name, e.g. PointCloudLazToPotree.
	 */
	public taskResultMap: { [key: string]: TaskResult } = {};
	/**
	 * Total number of tasks in currently running migrations.
	 * (I tried using a getter based on taskResultMap, but that didn't update correctly.)
	 */
	public nTasksTotal: number = 0;
	/**
	 * Number of successfully finished tasks in currently running migrations.
	 */
	public nTasksComplete: number = 0;
	/**
	 * Number of failed tasks in currently running migrations.
	 */
	public nTasksError: number = 0;
	/**
	 * Map from workspace UUID to duration info object.
	 */
	public durationMap: { [key: string]: DurationInfo } = {};
	/**
	 * The estimated durations of the migration item that the user selected for migration.
	 */
	public durationsOfSelected?: DurationInfo = undefined;
	/**
	 * Flag if the workspace belongs to a distributor.
	 */
	public distributorWorkspace: boolean = false;
	/**
	 * All table headers.
	 */
	public headersAll = [
		{ // Index: 0
			text: 'G',
			value: 'Group',
			width: '2%',
		},
		{ // Index: 1
			text: 'Workspace',
			value: 'Workspace.Name',
			width: '19%',
		},
		{ // Index: 2
			text: 'ERP ID',
			value: 'Workspace.ErpId',
			width: '6%',
		},
		{ // Index: 3
			text: 'Region',
			value: 'Workspace.RegionUpper',
			width: '6%',
		},
		{ // Index: 4
			text: 'Paid',
			value: 'Workspace.CommerciallyRelevant',
			width: '5%',
		},
		{ // Index: 5
			text: 'Subscription',
			value: 'Workspace.SubscriptionShortName',
			width: '8%',
		},
		{ // Index: 6 (requires showAllCols)
			text: 'Est. Dur.',
			value: 'EstDurationSec',
			width: '6%',
		},
		{ // Index: 7 (requires showAllCols)
			text: 'Last Update',
			value: 'LastUpdate',
			width: '8%',
		},
		{ // Index: 8 (requires showAllCols)
			text: '# Proj.',
			value: 'NumProjects',
			width: '5%',
		},
		{ // Index: 9
			text: 'Last Migration',
			value: 'Migrations[0].startOrScheduledOrCreationDate',
			width: '9%',
		},
		{ // Index: 10
			text: 'Read-only',
			value: 'Workspace.ReadOnly',
			width: '5%',
		},
		{ // Index: 11
			text: 'Redirect',
			value: 'Workspace.XgRedirect',
			width: '6%',
		},
		{ // Index: 12
			text: 'Status',
			value: 'StateCaption',
			width: '11%',
		},
		{ // Index: 13
			text: '',
			value: 'Actions',
			sortable: false,
			width: '3%',
		},
	];
	/**
	 * Default table headers without the optional ones.
	 */
	public headers = [
		this.headersAll[0],
		this.headersAll[1],
		this.headersAll[2],
		this.headersAll[3],
		this.headersAll[4],
		this.headersAll[5],
		this.headersAll[9],
		this.headersAll[10],
		this.headersAll[11],
		this.headersAll[12],
		this.headersAll[13],
	];

	/**
	 * Flag if the migration is going to be scheduled.
	 */
	public scheduleMigration = false;
	/**
	 * Datetime for the migration to start - minimum value, e.g. '2024-04-02T12:43' (timezone-less value).
	 */
	public migrationScheduleMin = new Date().toISOString().substring(0, 16);
	/**
	 * Datetime for the migration to start - initial value, e.g. '2024-04-02T12:43' (timezone-less value).
	 * Set to next day at 00:00 in onShowSelectProjectsDlg().
	 */
	public migrationScheduleInit = this.migrationScheduleMin;
	/**
	 * Datetime for the migration to start.
	 */
	public migrationSchedule = this.migrationScheduleInit;

	/**
	 * Flag if only the workspace owner and FARO users should be migrated.
	 */
	public onlyFaroUsers = false;

	/**
	 * Flag if the migration should be automatically published on successful migration.
	 */
	public autoPublish = true;

	// ---------------------------------------------------------------------------

	/**
	 * Applies the input of the search field as filter for the table items.
	 * The 3 search types can be combined by adding a space between them, e.g. "faro [eu] [Published]".
	 * @author OK
	 */
	public get filteredItems(): MigrationItem[] {
		const txtFull = this.$tsStore.pages.searchTxt.toLowerCase();
		// Users sometimes write e.g. '[pro][demo]' instead of '[pro] [demo]'. Let's allow it for convenience.
		const txts = txtFull.replace(/\]\[/g, '] [')
			.split(/(\s+)/).filter((t: string) => 0 < t.trim().length);

		return this.items.filter((item: MigrationItem): boolean => {
			for (const txt of txts) {
				if (txt === '[eu]' || txt === '[us]') {
					if (`[${item.Workspace.Region}]` !== txt) {
						return false;
					}
				} else if (txt === '[paid]' || txt === '[unpaid]') {
					const paid = txt === '[paid]';
					if (item.Workspace.CommerciallyRelevant !== paid) {
						return false;
					}
				} else if (txt === '[ownerfaro]' || txt === '[ownerdummy]' || txt === '[ownerother]' || txt === '[owner?]') {
					const emailType: EmailType =
						txt === '[ownerfaro]' ? 'faro' : (txt === '[ownerdummy]' ? 'dummy' :
							(txt === '[ownerother]' ? 'other' : null)
						);
					if (item.Workspace.OwnerEmailType !== emailType) {
						return false;
					}
				} else if (txt === '[demo]' || txt === '[!demo]') {
					const demo = txt === '[demo]';
					// If the subscription is undefined, we don't know, so we keep that workspace in the list.
					if (item.Workspace.Subscription && item.Workspace.Subscription.isTrial !== demo) {
						return false;
					}
				} else if (txt === '[base]' || txt === '[!base]' || txt === '[pro]' || txt === '[ent]' || txt === '[enterprise]' ||
					txt === '[wsbase]' || txt === '[wspro]' || txt === '[!sub]'
				) {
					if (item.Workspace.Subscription) {
						if (txt === '[base]' && item.Workspace.Subscription.type !== 'faro_sphere_base') {
							return false;
						} else if (txt === '[!base]' && item.Workspace.Subscription.type === 'faro_sphere_base') {
							return false;
						} else if (txt === '[pro]' && item.Workspace.Subscription.type !== 'faro_sphere_professional') {
							return false;
						} else if ((txt === '[ent]' || txt === '[enterprise]') && item.Workspace.Subscription.type !== 'faro_sphere_enterprisecloud') {
							return false;
						} else if (txt === '[wsbase]' && !item.Workspace.Subscription.type?.endsWith('_BASE')) {
							return false;
						} else if (txt === '[wspro]' && !item.Workspace.Subscription.type?.endsWith('_PRO')) {
							return false;
						}
					}
					if (txt === '[!sub]' && item.Workspace.SubscriptionShortName !== 'n/a') {
						return false;
					}
				} else if (txt === '[pending]' || txt === '[running]' || txt === '[unpublished]' ||
					txt === '[published]' || txt === '[upgraded]' || txt === '[!upgraded]' ||
					txt === '[aborted]' || txt === '[aborting]' || txt === '[failed]' ||
					txt === '[pe' || txt === '[r' || txt === '[un' ||
					txt === '[pu' || txt === '[up' || txt === '[!up' || txt === '[a' || txt === '[f'
				) {
					// Any state that has occurred shall be considered.
					const captions = item.StateCaptions;
					let captionsStr = '';
					for (const caption of captions) {
						captionsStr += `[${caption.toLowerCase()}]`;
					}
					if (txt === '[!upgraded]' || txt === '[!up') {
						if (captionsStr.includes('[upgraded]')) {
							return false;
						}
					} else {
						if (!captionsStr.includes(txt)) {
							return false;
						}
					}
				} else if (txt === '[mig]') {
					if (!item.Migrations?.length) {
						return false;
					}
				} else if (txt === '[!mig]') {
					if (item.Migrations?.length) {
						return false;
					}
				} else if (txt === '[deleted]') {
					if (!item.Migration?.DataDeleted) {
						return false;
					}
				} else if (txt === '[!deleted]') {
					if (item.Migration?.DataDeleted) {
						return false;
					}
				} else if (txt === '[empty]') {
					if (item.NumProjects) {
						return false;
					}
				} else if (txt === '[!empty]') {
					if (!item.NumProjects) {
						return false;
					}
				} else if (txt === '[stream]' || txt === '[flash]' || txt === '[flatness]') {
					if (!item.Workspace?.Features) {
						return false;
					}
					const rx = /\[(.*?)\]/g; // to extract module name between the square brackets
					const arr = rx.exec(txt);
					if (arr !== null) {
						/*
						Possible modules:
						"sphere:feature:module:stream",
						"sphere:feature:module:streamquickscan",
						"sphere:feature:module:flatness-check"
						*/
						let searchModule = arr[1];
						if (searchModule === 'flash') {
							searchModule = 'streamquickscan';
						} else if (searchModule === 'flatness') {
							searchModule = 'flatness-check';
						}
						if (!item.Workspace.Features.includes(`sphere:feature:module:${searchModule}`)) {
							return false;
						}
					}
				} else if (txt.startsWith('[g')) {
					const groupRaw = txt.endsWith(']') ? txt.substring(2, txt.length - 1) : txt.substring(2);
					const group = groupRaw === '-' ? null : groupRaw;
					if (group === '*' && !item.Group) {
						return false;
					} else if (group !== '*' && item.Group !== group) {
						return false;
					}
				} else {
					if (!item.WorkspaceDisplayName.toLowerCase().includes(txt) && !item.Workspace.ErpId?.toLowerCase().includes(txt)) {
						return false;
					}
				}
			}

			// Each provided search type matched:
			return true;
		});
	}

	/**
	 * Returns the percentage string for finished projects compared to total projects in currently running migrations.
	 * @author OK
	 */
	public get projectsPercent(): string {
		if (this.nProjectsTotal === 0) {
			return '0%';
		}
		const percent = 100 * (this.nProjectsComplete + this.nProjectsError) / this.nProjectsTotal;
		return percent.toFixed(0) + '%';
	}

	/**
	 * Returns the percentage string for finished tasks compared to total tasks in currently running migrations.
	 * @author OK
	 */
	public get tasksPercent(): string {
		if (this.nTasksTotal === 0) {
			return '0%';
		}
		const percent = 100 * (this.nTasksComplete + this.nTasksError) / this.nTasksTotal;
		return percent.toFixed(0) + '%';
	}

	/**
	 * Returns the percentage string for finished tasks of the provided type compared to total tasks of the provided
	 * type in currently running migrations.
	 * @author OK
	 */
	public taskPercent(result: TaskResult): string {
		if (result.total === 0) {
			return '0%';
		}
		const percent = 100 * (result.complete + result.error) / result.total;
		return percent.toFixed(0) + '%';
	}

	public gbStr(gb: number): string {
		return gb.toFixed(1) + ' GB';
	}

	// ---------------------------------------------------------------------------

	/**
	 * Gets the estimated durations for all migration items in the selected group.
	 * @author OK
	 */
	public async getEstimatedDurations(): Promise<void> {
		const limiter = new Bottleneck({ maxConcurrent: 8 });
		const promises: Promise<void>[] = [];

		for (const item of this.items) {
			if (item.Group === this.groupForEstDurations && !this.durationMap[item.Workspace.UUID]) {
				const promise = limiter.schedule(async () => {
					await this.calcEstimatedDurations(item.Workspace);
				});
				promises.push(promise);
			}
		}

		// We can already show the column while we not having received all responses yet.
		this.showAllCols = true;
		await Promise.allSettled(promises);
	}

	/**
	 * Returns true if a running migration has exceeded the maximum estimated duration.
	 * @author OK
	 */
	public overEstimatedDuration(item: MigrationItem): boolean {
		const migration = item.Migrations?.[0];
		const durationInfo = this.durationMap[item.Workspace.UUID];
		if (!migration || migration.State !== 'running' || !durationInfo) {
			return false;
		}

		const durationMs = Migration.getDurationMs(migration.StartDate || undefined, migration.FinishDate || undefined, true);
		const durationSec = durationMs / 1000;
		return (!!durationInfo.Max && !!durationSec) ? durationInfo.Max < durationSec : false;
	}

	/**
	 * Gets the actions buttons for the provided entity.
	 * The entity will be included in the first parameter for the click callback in each item button.
	 * @param item The selected entity.
	 * @returns An array filled with icon buttons.
	 */
	public getItemButtons(item: MigrationItem): Array<FaroIconButtonI> {
		const buttons: (FaroIconButtonExtendedMigrationItem | FaroDividerButton)[] = [];

		buttons.push({
			icon: '$vuetify.icons.36_generic-plus',
			caption: 'New Migration',
			click: (item: MigrationItem): void => {
				void this.onShowSelectProjectsDlg(item);
			},
			getHidden: (item: MigrationItem): boolean => {
				return !this.canStartMigration(item);
			},
			dataCy: 'mdb_new_migration',
		});

		if (!(item.Migrations && item.Migrations.length &&
			(item.Migrations[0].State === 'running' || item.Migrations[0].State === 'pending'))
		) {
			buttons.push({divider: true});
		}

		// Add the "Go to Details page" button for the 10 newest migrations, and additionally all older pending and
		// running migrations.
		if (item.Migrations && item.Migrations.length) {
			const maxItems = this.showAllMigrations ? 999999 : 10;
			let addedDivider: boolean = false; // Prevent adding two dividers.
			let i = 0;

			for (const migration of item.Migrations) {
				if (i < maxItems || migration.State === 'pending' || migration.State === 'running') {
					i++;

					addedDivider = false;
					const projects = !migration.Projects?.length ? 'All Projects' :
						(migration.Projects?.length > 1 ? `${migration.Projects?.length} Projects` : '1 Project');
					const scheduledDate = (migration.ScheduledDate && migration.State === 'pending') ?
						(migration.ScheduledDate.substring(0, 10) + ' ' + migration.ScheduledDate.substring(11, 16) + ' (UTC)') : '';
					const duration = migration.getMigrationDuration();
					const stateTxt = migration.getCaption();
					const caption = `${migration.prettyStartDateTimeNoDash} - ${stateTxt}` +
						(migration.XgRedirect && migration.State !== 'upgraded' ? ' +Redirect' : '') +
						(scheduledDate ? ` - scheduled for: ${scheduledDate}` : '') +
						(duration ? ` (${projects} - ${duration})` : ` (${projects})`);

					const button: FaroIconButtonExtendedMigrationItem = {
						icon: migration.getInfoIcon(migration.State),
						caption,
						click: (it: MigrationItem): void => {
							// `window.event` is deprecated, but it works.
							// It would be nicer to have a native <a> link, but seems not available on FaroIconButtonExtendedMigrationItem.
							const newTab = !!(window as any).event?.ctrlKey;
							this.onViewDetails(it, migration, newTab);
						},
						getHidden: (it: MigrationItem): boolean => {
							return it !== item || !this.canViewDetails(migration);
						},
						textClass: stateTxt === 'Upgraded' ? 'migration-upgraded-bold' : undefined,
						dataCy: `mdb_details_${migration.UUID}`,
					};
					buttons.push(button);

					if (!addedDivider && (migration.State === 'pending' || migration.State === 'running')) {
						buttons.push({divider: true});
						addedDivider = true;
					}
				}
			}

			if (!addedDivider) {
				buttons.push({divider: true});
			}
		}

		buttons.push({
			icon: '$vuetify.icons.36_generic-arrows-switch',
			caption: 'Toggle Read-only State',
			click: (item: MigrationItem): void => {
				this.onToggleReadOnlyConfirm(item);
			},
			getHidden: (item: MigrationItem): boolean => {
				return !this.canToggleReadOnly(item);
			},
			dataCy: 'mdb_toggle_readonly',
		});

		return buttons.map((btn: any) => {
			return {
				...btn,
				hide: btn.getHidden ? btn.getHidden(item) : false,
				disabled: btn.getDisabled ? btn.getDisabled(item) : false,
				click: btn.click ? btn.click.bind(btn, item) : undefined,
			};
		});
	}

	/**
	 * Returns the caption to use for the item's state.
	 * @author OK
	 */
	public getCaption(item: MigrationItem): string {
		return item.StateCaption;
	}

	/**
	 * Returns the CSS class to use for the item's state.
	 * @author OK
	 */
	public getCss(item: MigrationItem, stateInfo: MigrationStateEntry): string {
		return item.Migration ? item.Migration.getCss(stateInfo.state as AnyState) : 'ready';
	}

	/**
	 * Returns the icon to use for the item's state, as an image <img/>.
	 * @author MF
	 */
	public getIcon(item: MigrationItem, stateInfo: MigrationStateEntry): string {
		return item.Migration ? item.Migration.getIcon(stateInfo.state as AnyState) : '/home/img/action-help_l.0250bb91.svg';
	}

	/**
	 * Returns the caption to use for the item's state.
	 * @author OK
	 */
	public getCaption2(item: MigrationItem, stateInfo: MigrationStateEntry): string {
		return item.Migration ? item.Migration.getCaption(stateInfo.state as AnyState) : 'Ready';
	}

	public getUpgradedWarning(item: MigrationItem): string {
		if (item.Migration?.State !== 'upgraded') {
			return '';
		}

		if (!item.Workspace) {
			return 'Cannot do complete check - Workspace not accessible';
		} else if (!item.Migration.Projects) {
			return 'Cannot do complete check - Migration.Projects not accessible';
		} else if (!item.stateInfoArray) {
			return 'Cannot do complete check - item.stateInfoArray not accessible';
		} else if (!item.Migration.EmailSent && item.Migration.CreationDate >= Migration.SUCCESS_EMAILS_SENT_SINCE) {
			return 'Success emails not sent';
		} else if (item.Migration.Projects?.length) {
			return 'Not all projects were selected for migration';
		} else if (item.Migration.UserSelection) {
			return 'Not all users were selected for migration';
		} else if (!item.Workspace.ReadOnly) {
			return 'Workspace is not read-only';
		} else if (!item.Workspace.XgRedirect) {
			return 'Workspace is not redirecting';
		} else if (item.Workspace.CommerciallyRelevant && this.countUpgradedOrPublished(item) > 1) {
			return 'Paid workspace, and more than one migration in status "Published" or "Upgraded"';
		}
		return '';
	}

	/**
	 * [TF-1592] If the workspace is paid: There must not be another migration in status “Published” or “Upgraded” for the same workspace.
	 * @author MF
	 */
	public countUpgradedOrPublished(item: MigrationItem) {
		let count = 0;
		if (item.stateInfoArray) {
			for (const si of item.stateInfoArray) {
				if (si.state === 'published' || si.state === 'upgraded') {
					count += si.count;
				}
			}
		}
		return count;
	}

	/**
	 * Handler for the button to toggle the read-only state for the item's workspace.
	 * Shows a confirmation dialog before calling onToggleReadOnly.
	 * @author OK
	 */
	public onToggleReadOnlyConfirm(item: MigrationItem): void {
		const workspace = item.Workspace;
		const msg = `Please confirm the change of the read-only flag:\n\nWorkspace: ${workspace.Name}\nRead-only: ${workspace.ReadOnly} ➔ ${!workspace.ReadOnly}`;

		this.$faroNotify.showConfirmationDialog({
			text: msg,
			title: 'Toggle Read-only State',
			group: 'info',
			leftButton: {
				textId: 'UI_CANCEL',
			},
			rightButton: {
				color: 'info',
				textId: 'UI_OK',
				click:  () => this.onToggleReadOnly(item),
			},
		});
	}

	/**
	 * Handler for the button to toggle the read-only state for the item's workspace after the user has confirmed the
	 * action in onToggleReadOnlyConfirm.
	 * @author OK
	 */
	public async onToggleReadOnly(item: MigrationItem): Promise<void> {
		const workspace = item.Workspace;
		const readOnly = !workspace.ReadOnly;
		try {
			await this.$tsStore.migrations.setReadOnly({
				region: workspace.Region as AuthzType.WebshareRegion,
				uuidWorkspace: workspace.UUID,
				readOnly,
			});
			this.$tsStore.migrationWorkspaces.setReadOnlyState({
				workspaceUuid: workspace.UUID,
				readOnly,
				xgRedirect: workspace.XgRedirect,
			});
			item.Workspace = this.$tsStore.migrationWorkspaces.ItemsMap[workspace.UUID];
		} catch (error) {
			const message = this.$tc('LP_ERR_READ_ONLY') + ' ' + workspace.Name;
			this.$faroComponents.$emit('show-error', { error, message });
		}
	}

	/**
	 * Handler for the button to reset the WorkerLock table to its initial state in the EU region.
	 * @author OK
	 */
	public async onResetLockTableEU(): Promise<void> {
		try {
			await this.$tsStore.migrations.resetLockTable('eu');
			this.$faroNotify.showSnackbar('success', 'Lock table in region EU successfully resetted.');
		} catch (error) {
			const message = this.$tc('LP_ERR_RESET_LOCK_TABLE');
			this.$faroComponents.$emit('show-error', { error, message });
		}
	}

	/**
	 * Handler for the button to reset the WorkerLock table to its initial state in the US region.
	 * @author OK
	 */
	public async onResetLockTableUS(): Promise<void> {
		try {
			await this.$tsStore.migrations.resetLockTable('us');
			this.$faroNotify.showSnackbar('success', 'Lock table in region US successfully resetted.');
		} catch (error) {
			const message = this.$tc('LP_ERR_RESET_LOCK_TABLE');
			this.$faroComponents.$emit('show-error', { error, message });
		}
	}

	public getMigrationScheduleUTC(): string {
		if (!this.scheduleMigration || !this.migrationSchedule) {
			return '';
		}
		// Treat timezone-less value as UTC.
		return new Date(this.migrationSchedule + 'Z').toISOString();
	}

	public getMigrationScheduleHuman(): string {
		if (!this.scheduleMigration || !this.migrationSchedule) {
			return '';
		}
		const schedule = new Date(this.migrationSchedule + 'Z');
		const msec = Math.max(0, schedule.valueOf() - new Date().valueOf());
		const daysFrac = msec / 86400000;
		const days = Math.floor(daysFrac);
		const hours = (daysFrac - days) * 24;
		return days > 0 ? `in ${days} days, ${hours.toFixed(1)} hours` : `in ${hours.toFixed(1)} hours`;
	}

	/**
	 * Handler for the OK button in the project selection dialog to start the migration for the item.
	 * @author OK
	 */
	public async onStartMigration(): Promise<void> {
		$assert.Assert(this.selectedItem);

		this.showSelectProjectsDlg = false;

		const workspace = this.selectedItem!.Workspace;
		try {
			const migration = await this.$tsStore.migrations.createMigration({
				region: workspace.Region as AuthzType.WebshareRegion,
				migration: {
					Class: 'Migration',
					Workspace: workspace.UUID,
					Projects: this.selectAllProjects ? [] : this.selectedProjects,
					ScheduledDate: this.getMigrationScheduleUTC() || undefined,
					UserSelection: this.onlyFaroUsers ? 'faro' : null,
					AutoPublish: this.autoPublish,
					DistributorWorkspace: this.distributorWorkspace,
				},
			});

			// Open the Migration page in a new tab. Not using a new tab would unload the Dashboard page and
			// in particular requested duration info.
			const routeMigrationPage = this.$router.resolve({ name: 'MigrationPage',
				params: { workspace: workspace.UUID, migration: migration.UUID }});
			window.open(routeMigrationPage.href, '_blank');

			// Save some configs in localstorage to fill automatically next time
			let saveConfigs: any = {};
			if (migration.ScheduledDate) {
				const dateNext = this.calcNextScheduledDate(workspace, migration.ScheduledDate);
				const dateNextStr = dateNext.toISOString().substring(0, 16); // e.g. '2024-04-02T00:00' (timezone-less value)

				// We need to keep the stored value for the other region.
				let oldConfigs: any;
				try {
					oldConfigs = JSON.parse(localStorage.getItem('migrationConfigs') || '{}');
				} catch (e: any) {
					console.error('Error reading local storage migration configs:', e);
				}

				if (workspace.Region === 'eu') {
					saveConfigs.NextScheduledDateEU = dateNextStr;
					if (oldConfigs && oldConfigs.NextScheduledDateUS) {
						saveConfigs.NextScheduledDateUS = oldConfigs.NextScheduledDateUS;
					}
				} else {
					saveConfigs.NextScheduledDateUS = dateNextStr;
					if (oldConfigs && oldConfigs.NextScheduledDateEU) {
						saveConfigs.NextScheduledDateEU = oldConfigs.NextScheduledDateEU;
					}
				}
				saveConfigs.scheduleMigration = true;
			}
			if (migration.UserSelection) {
				saveConfigs.UserSelection = migration.UserSelection;
			}
			localStorage.setItem('migrationConfigs', JSON.stringify(saveConfigs));

			// The selected migration item needs to be updated. That's most easily done by calling initFromMigrations
			// for all migrations.
			await this.initFromMigrations();
		} catch (error) {
			const message = this.$tc('LP_ERR_MIGRATION_START') + ' ' + workspace.Name;
			this.$faroComponents.$emit('show-error', { error, message });
		}
	}

	/**
	 * Set a gap to the start time of the next migration in the same region according to the following table
	 * containing the estimated duration of the migration.
	 *   0-1 hours: 10 minutes
	 *   1-8 hours: 20 minutes
	 *   8-16 hours: 30 minutes
	 *   16-24 hours: 40 minutes
	 *   1-2 days: 1 hour
	 *   2-3 days: 1 hour 30 minutes
	 *   3-4 days: 2 hours
	 *   4+ days: 2 hours 30 minutes
	 * @author OK
	 */
	protected calcNextScheduledDate(workspace: Workspace, scheduledDate: string): Date {
		// calcEstimatedDurations is called in onShowSelectProjectsDlg and so durationMap should always be defined.
		const durationInfo = this.durationMap[workspace.UUID];
		const durationH = durationInfo ? durationInfo.Standard / 3600 : 0;

		let incrementM = 10;
		if (1 <= durationH && durationH < 8) {
			incrementM = 20;
		} else if (8 <= durationH && durationH < 16) {
			incrementM = 30;
		} else if (16 <= durationH && durationH < 24) {
			incrementM = 40;
		} else if (24 <= durationH && durationH < 48) {
			incrementM = 60;
		} else if (48 <= durationH && durationH < 72) {
			incrementM = 90;
		} else if (72 <= durationH && durationH < 96) {
			incrementM = 120;
		} else if (96 <= durationH) {
			incrementM = 150;
		}

		const date = new Date(scheduledDate);
		date.setUTCMinutes(date.getUTCMinutes() + incrementM);
		return date;
	}

	/**
	 * Returns true if the button to start the migration should be visible for the item.
	 * @author OK
	 */
	protected canStartMigration(item: MigrationItem): boolean {
		return isFeatureEnabledByAuth0PermissionsSync('migrateWorkspaces') &&
			(isFeatureEnabledByAuth0PermissionsSync('viewSubscriptions') || isFeatureEnabledByAuth0PermissionsSync('adminSubscriptions')) &&
			(!item.Migration || (item.Migration.State !== 'pending' && item.Migration.State !== 'running' && !item.Migration.DataDeleted));
	}

	/**
	 * Returns true if the button to toggle the workspace read-only state should be visible for the item.
	 * We allow this in any migration state, since we may want to trigger a test migration without disrupting the customer's work.
	 * If the customer doesn't delete anything from the project, it's unlikely that edit operations will cause migration issues.
	 * @author MH
	 */
	protected canToggleReadOnly(item: MigrationItem): boolean {
		return isFeatureEnabledByAuth0PermissionsSync('migrateWorkspaces') &&
			(isFeatureEnabledByAuth0PermissionsSync('viewSubscriptions') || isFeatureEnabledByAuth0PermissionsSync('adminSubscriptions')) &&
			(!item.Migration || !item.Migration.DataDeleted);
	}

	/**
	 * Returns true if the button to view details of the migration should be visible for the item.
	 * @param migration The migration for which the button should be displayed. (A workspace can have multiple
	 *        migrations.)
	 * @author OK
	 */
	protected canViewDetails(migration: Migration): boolean {
		return isFeatureEnabledByAuth0PermissionsSync('viewMigrations') &&
			!!migration;
	}

	/**
	 * Loads the SSO data vor all migrations and stores the data in this.ssoData.
	 * @author BE
	 */
	protected async getSsoData(): Promise<void> {
		const customHeaders = await BaseServiceAny.getCustomHeaders();
		const client = new HttpClient();
		const url = `${config.ssoConfigApi}/sso-config/v1/connections?globalAdmin=true`;
		let ssoConfigs: any = undefined;
		try {
			ssoConfigs = await client.get(url, { customHeaders });
		} catch (e) {
			// Usually 403
			console.warn('The user has no access to the SSO configuration API.');
			return;
		}
		try {
			const ssoData: {[key: string]: any} = {};
			for (const ssoConfig of ssoConfigs) {
				if (ssoConfig.State !== 'new' && ssoConfig.State !== 'pending-deletion' && ssoConfig.State !== 'deleted') {
					for (const workspaceUUID of ssoConfig.AllWorkspaceUUIDs) {
						ssoData[workspaceUUID] = ssoConfig.SsoEmailDomains;
					}
				}
			}
			this.ssoData = ssoData;
		} catch (e) {
			console.error('Error parsing SSO data:', e);
		}
	}

	/**
	 * Returns true if the hint for SSO should be shown for the selected workspace.
	 * @author BE
	 */
	public showSsoHint(): boolean {
		$assert.Assert(this.selectedItem);
		return this.ssoData && this.selectedItem?.Workspace.UUID && this.ssoData[this.selectedItem.Workspace.UUID];
	}

	/**
	 * Returns the SSO email domains for the selected workspace as comma-separated string.
	 */
	public getSsoEmailDomains(): string {
		$assert.Assert(this.selectedItem);
		const ssoDomains = this.selectedItem?.Workspace.UUID ? this.ssoData[this.selectedItem.Workspace.UUID] : undefined;
		return ssoDomains ? ssoDomains.join(', ') : '';
	}

	/**
	 * Handler for the button to view details of the migration for the item.
	 * @param item
	 * @param migration The migration for which the button should be displayed. (A workspace can have multiple migrations.)
	 * @param newTab True to open the migration in a new tab. The tab is not focused, like the default browser behavior when using <Ctrl>.
	 * @author OK
	 */
	protected onViewDetails(item: MigrationItem, migration: Migration, newTab = false): void {
		const params = { workspace: item.Workspace.UUID, migration: migration.UUID };
		if (newTab) {
			const route = this.$router.resolve({ name: 'MigrationPage', params });
			WebUtils.openExternalLink(route.href, '_blank', /*windowFeatures*/ '', /*focus*/ false);
		} else {
			this.$router.push({ name: 'MigrationPage', params }).catch((error: any) => {
				console.error('Error navigating to MigrationPage:', error);
			});
		}
	}

	protected getLocalMigrationConfig(property: string, defaultVal: any) {
		try {
			const localConfigs = JSON.parse(localStorage.getItem('migrationConfigs') || '{}');
			return (localConfigs && localConfigs[property]) ? localConfigs[property] : defaultVal;
		} catch (e: any) {
			console.error('Error reading local storage migration configs:', e);
			return defaultVal;
		}
	}

	/**
	 * Handler for the button to show the project selection dialog before starting the migration.
	 * @author OK
	 */
	protected async onShowSelectProjectsDlg(item: MigrationItem): Promise<void> {
		this.projectsExtended = [];
		this.projectsExtendedFiltered = [];
		this.selectAllProjects = true;
		this.showProjects = false;
		this.selectedProjects = [];
		this.selectedItem = item;
		// Default: Don't schedule the migration; if the box is checked, default value = today 21:00 UTC or next day 00:00 UTC.
		this.scheduleMigration = this.getLocalMigrationConfig('scheduleMigration', false);
		this.migrationScheduleMin = new Date().toISOString().substring(0, 16);
		const scheduleInit = new Date();
		// If it's already late, default = 00:00 UTC; otherwise default = 21:00 UTC.
		if (scheduleInit.getUTCHours() >= 20) {
			scheduleInit.setUTCDate(scheduleInit.getUTCDate() + 1);
			scheduleInit.setUTCHours(0, 0, 0, 0);
		} else {
			scheduleInit.setUTCHours(21, 0, 0, 0);
		}
		this.migrationScheduleInit = scheduleInit.toISOString().substring(0, 16); // e.g. '2024-04-02T00:00' (timezone-less value)
		const scheduledDateProp = (item.Workspace.Region === 'eu') ? 'NextScheduledDateEU' : 'NextScheduledDateUS';
		this.migrationSchedule = this.getLocalMigrationConfig(scheduledDateProp, this.migrationScheduleInit);
		if (this.migrationSchedule && this.migrationSchedule < this.migrationScheduleMin) {
			// Don't use dates from the past.
			this.migrationSchedule = this.migrationScheduleInit;
		}
		// Always prefill with "false"; would be bad if accidentally checked.
		this.onlyFaroUsers = false;
		try {
			this.projectsExtended = await this.getProjectsForMigration(item);

			if (!this.durationMap[item.Workspace.UUID]) {
				await this.calcEstimatedDurations(item.Workspace);
			}
			this.durationsOfSelected = this.durationMap[item.Workspace.UUID];

			this.projectsExtendedFiltered = this.projectsExtended.filter(() => true); // shallow clone
			this.showSelectProjectsDlg = true;
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error });
		}
	}

	/**
	 * Handler for the search box to update the list of shown projects.
	 * @author BE
	 */
	public onUpdateFilteredProjects(searchStringRaw: string | undefined): void {
		const searchString = searchStringRaw?.trim()?.toLocaleLowerCase();
		if (!searchString) {
			this.projectsExtendedFiltered = this.projectsExtended.filter(() => true); // shallow clone
		} else {
			this.projectsExtendedFiltered = this.projectsExtended.filter((p) => {
				return p.AZ.Name?.toLocaleLowerCase().includes(searchString);
			});
		}
	}

	/**
	 * Handler for the "All Projects" checkbox.
	 * @author MH
	 */
	public onSelectAllProjects(): void {
		this.showProjects = true;
		this.selectedProjects = this.selectAllProjects ? (this.projectsExtended.map((p) => p.AZ.UUID).filter(uuid => !!uuid) as string[]) : [];
	}

	/**
	 * Handler for the button to show the dialog box with the workspace statistics.
	 * @author OK
	 * @param item Migration item selected by the user.
	 */
	public async showWorkspaceStats(item: MigrationItem): Promise<void> {
		this.$faroLoading.start();
		try {
			const statsObj = await this.$tsStore.migrations.getWorkspaceStats({
				region: item.Workspace.Region as AuthzType.WebshareRegion,
				uuidWorkspace: item.Workspace.UUID,
			});
			this.statsObj = statsObj;

			if (!this.durationMap[item.Workspace.UUID]) {
				await this.calcEstimatedDurations(item.Workspace);
			}

			this.showWorkspaceStatsDlg = true;
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error });
		}
		this.$faroLoading.stop();
	}

	/**
	 * Returns the region of the workspace of the provided workspace stats object.
	 * @author OK
	 */
	public getRegion(statsObj: WorkspaceStats): string {
		const workspace = this.$tsStore.migrationWorkspaces.ItemsMap[statsObj.Workspace];
		return workspace.Region;
	}

	/**
	 * Handler for the button to download the workspace statistics.
	 * @param statsObj The workspace statistics object to download.
	 * @author OK
	 */
	public downloadWorkspaceStats(statsObj: WorkspaceStats): void {
		const workspace = this.$tsStore.migrationWorkspaces.ItemsMap[statsObj.Workspace];
		// We don't want the other irrelevant attributes of the WorkspaceStats class.
		const obj = {
			Workspace: statsObj.Workspace,
			Region: workspace.Region,
			Paid: workspace.CommerciallyRelevant,
			Subscription: workspace.SubscriptionShortName,
			StatsAggregated: statsObj.StatsAggregated,
			StatsProjects: statsObj.StatsProjects,
			EstimatedDuration: this.durationMap[workspace.UUID],
		};
		this.saveAsJsonFile(`workspace-stats - ${workspace.Name}.json`, obj);
	}

	/**
	 * Handler for the button to download the aggregated workspace statistics.
	 * @author OK
	 */
	public async downloadAggregatedStats(): Promise<void> {
		this.$faroLoading.start();

		try {
			let statsObjs: StatsAggregatedMap = await this.$tsStore.migrations.getAggregatedStatsGlobal();

			for (const workspaceUuid in statsObjs) {
				const workspace = this.$tsStore.migrationWorkspaces.ItemsMap[workspaceUuid];
				if (!workspace) {
					continue;
				}
				statsObjs[workspaceUuid].Region = workspace.Region;
				statsObjs[workspaceUuid].Paid = workspace.CommerciallyRelevant;
				statsObjs[workspaceUuid].Subscription = workspace.SubscriptionShortName;
				statsObjs[workspaceUuid].EstimatedDuration = this.durationMap[workspace.UUID];

				statsObjs[workspaceUuid] = sortObject(statsObjs[workspaceUuid]);
			}

			statsObjs = sortObject(statsObjs);
			this.saveAsJsonFile(`workspace-stats ${config.env}.json`, statsObjs);
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error });
		}
		this.$faroLoading.stop();
	}

	public async downloadMigrationList(): Promise<void> {
		this.$faroLoading.start();
		let statsObjs: StatsAggregatedMap = {};
		try {
			statsObjs = await this.$tsStore.migrations.getAggregatedStatsGlobal();
		} catch (error) {
			const title = 'Failed to fetch workspace statistics. Workspace sizes will not be available in the export.';
			this.$faroComponents.$emit('show-error', { error, title });
		}
		this.$faroLoading.stop();

		// Tabs make the file better readable in a text editor, compared to commas.
		// Excel apparently doesn't recognize the *.tsv extension, so we use *.csv.
		const SEP = '\t';
		const DECIMAL_SEP = (1.1).toLocaleString().substring(1, 2);
		let csv = 'sep=' + SEP + '\n';
		csv += [
			'Group (might be outdated, check Confluence)', 'Workspace', 'ERP ID', 'Region', 'Paid', 'Subscription',
			`Data to migrate = ${Migration.SIZE_FACTOR} * WebShare storage [GB]`, 'Size of point clouds [GB]',
			'Number of Scans', 'Number of Point Clouds', 'Number of CAD Objects',
			'Read-only', 'Redirect',
			'Highest Migration: Start Date', 'Highest Migration: Status', 'Highest Migration: Projects', 'Highest Migration: Result',
		].join(SEP) + '\n';

		// We use the initial sort order; see call to this.items.sort(...).
		for (const item of this.items) {
			const migrations = (item.Migrations || []);
			const highestMigration: Migration | undefined =
				// For pilot customers, there may be an older migration, plus e.g. a scheduled one. -> Prefer the scheduled one.
				migrations.find((m) => m.State === 'pending') ||
				migrations.find((m) => m.State === 'running') ||
				// Otherwise, prefer the "highest" state.
				migrations.find((m) => m.State === 'upgraded') ||
				migrations.find((m) => m.State === 'published') ||
				migrations.find((m) => m.State === 'unpublished') ||
				migrations[0];

			// Using decimal separator from locale so that Excel can correctly parse the number by default.
			// E.g. German Excel: "1.4" gets parsed as "April 1st".
			const statsObj: StatsAggregatedI | undefined = statsObjs[item.Workspace.UUID];
			const sizeGB = statsObj?.Size;
			const sizeToMigrateGB = typeof sizeGB === 'number' ? (Migration.SIZE_FACTOR * sizeGB).toFixed(1).replace('.', DECIMAL_SEP) : '?';
			const pcsSizeGB = statsObj ? (statsObj.PCsSize || 0).toFixed(1).replace('.', DECIMAL_SEP) : '?';

			const line = [
				// Possible workaround to avoid number parsing in Excel.
				// (item.Workspace.Name.match(/^\d+$/) ? '_ ' : '') + item.Workspace.Name,
				item.Group || '-',
				item.Workspace.Name,
				item.Workspace.ErpId || '-',
				item.Workspace.RegionUpper,
				item.Workspace.CommerciallyRelevant ? 'Paid' : 'Unpaid',
				item.Workspace.SubscriptionShortName,
				sizeToMigrateGB,
				pcsSizeGB,
				statsObj ? (statsObj.Scans || 0) : '?',
				statsObj ? (statsObj.PCs || 0) : '?',
				statsObj ? (statsObj.CadObjects || 0) : '?',
				item.Workspace.ReadOnly ? 'Read-only' : '-',
				item.Workspace.XgRedirect ? 'Redirect' : '-',
				highestMigration?.StartDate?.substring(0, 19)?.replace('T', ' ') || '-',
				highestMigration?.State || '-',
				highestMigration?.Projects ? (highestMigration?.Projects.length || 'all') : '-',
				highestMigration?.xgDashboardUrl || '-',
			];
			csv += line.join(SEP) + '\n';
		}

		downloadBufferAsFile(csv, `migrations ${config.env}.csv`, 'text/csv');
	}

	/**
	 * Helper method to download an object as JSON file.
	 * @author OK
	 */
	protected saveAsJsonFile(filename: string, obj: { [key: string]: any }) {
		downloadBufferAsFile(JSON.stringify(obj, undefined, 2), filename, 'application/json');
	}

	/**
	 * Gets the projects from the Migrator that can potentially be migrated.
	 * @author OK
	 * @param item Migration item selected by the user.
	 */
	protected async getProjectsForMigration(item: MigrationItem): Promise<ProjectExtended[]> {
		return await this.$tsStore.migrations.getProjectsForMigration({
			region: item.Workspace.Region as AuthzType.WebshareRegion,
			uuidWorkspace: item.Workspace.UUID,
		});
	}

	// ---------------------------------------------------------------------------

	/**
	 * Gets all active workspaces from AuthZ and the Subscription Service and combines them.
	 * @author OK
	 */
	protected async getWorkspaces(): Promise<void> {
		this.workspaces = this.$tsStore.migrationWorkspaces.itemsList;
		// this.workspaces.length === 1 is checked because the user could have first accessed a deep link to
		// the Migration Details page.
		if (!this.workspaces || !this.workspaces.length || this.workspaces.length === 1) {
			await this.$tsStore.migrationWorkspaces.getAll();
			this.workspaces = this.$tsStore.migrationWorkspaces.itemsList;
		}
	}

	/**
	 * Calculates the estimated duration for the migration of the workspace, under the assumption that all projects
	 * were selected for migration. Then adds the result to durationMap.
	 * Catches and logs received error.
	 * @author OK
	 */
	protected async calcEstimatedDurations(workspace: Workspace): Promise<void> {
		try {
			const duration = await Migration.calcEstimatedDurations(workspace.Region as AuthzType.WebshareRegion, workspace.UUID);
			this.durationMap[workspace.UUID] = duration;
			const item = this.itemsMap[workspace.UUID];
			item.EstDurationSec = duration.Standard;
			item.EstDurationStr = duration.StandardShortStr;
		} catch (error) {
			console.error('Error trying to get workspace size for duration estimation!', error);
		}
	}

	/**
	 * Gets all migrations from the Migrator backend.
	 * @author OK
	 */
	protected async getMigrations(): Promise<void> {
		// This calls Migration.fromResponse() for each migration, which is expensive when polling, but not that easy to refactor.
		await this.$tsStore.migrations.getAllMigrations({ withSteps: false });
		await this.initFromMigrations();
	}

	/**
	 * Initializes the dashboard for all migrations received with getMigrations.
	 * No longer part of that method so that it can be called by onStartMigration without the initial server request.
	 * @author OK
	 */
	protected async initFromMigrations(): Promise<void> {
		const allMigrations = this.$tsStore.migrations.itemsList;

		const migrationsOfWorkspace: { [key: string]: Migration[] } = {};
		const latestUpdateDateOfWorkspace: Record<string, string> = {};
		// Collects info about the occurring migration states.
		const stateInfoArraysOfWorkspace: { [key: string]: MigrationStateEntry[] } = {};

		// Find the newest migration for each workspace:
		for (const migration of allMigrations) {
			const workspaceUuid = migration.Workspace;
			if (!migrationsOfWorkspace[workspaceUuid]) {
				migrationsOfWorkspace[workspaceUuid] = [];
			}
			migrationsOfWorkspace[workspaceUuid].push(migration);
			if (!latestUpdateDateOfWorkspace[workspaceUuid] || migration.UpdateDate > latestUpdateDateOfWorkspace[workspaceUuid]) {
				latestUpdateDateOfWorkspace[workspaceUuid] = migration.UpdateDate;
			}
		}

		const wasItemsEmpty = this.items.length === 0;

		// Sort the migrations of each workspace by StartDate/ScheduledDate/CreationDate (descending).
		for (const workspaceUuid in migrationsOfWorkspace) {
			const migrations = migrationsOfWorkspace[workspaceUuid];
			migrations.sort((a: Migration, b: Migration) => {
				return b.startOrScheduledOrCreationDate.localeCompare(a.startOrScheduledOrCreationDate);
			});

			// Find out which migration states occur.
			let stateInfoArray: MigrationStateEntry[] = [
				{
					state: 'pending',
					index: -1,
					count: 0,
				},
				{
					state: 'running',
					index: -1,
					count: 0,
				},
				{
					state: 'unpublished',
					index: -1,
					count: 0,
				},
				{
					state: 'published',
					index: -1,
					count: 0,
				},
				{
					state: 'upgraded',
					index: -1,
					count: 0,
				},
				{
					state: 'error',
					index: -1,
					count: 0,
				},
				{
					state: 'aborting',
					index: -1,
					count: 0,
				},
				{
					state: 'aborted',
					index: -1,
					count: 0,
				},
			];

			let index = 0;
			for (const migration of migrations) {
				let i: number;
				if (migration.State === 'pending') {
					i = 0;
				} else if (migration.State === 'running') {
					i = 1;
				} else if (migration.State === 'unpublished') {
					i = 2;
				} else if (migration.State === 'published') {
					i = 3;
				} else if (migration.State === 'upgraded') {
					i = 4;
				} else if (migration.State === 'error') {
					i = 5;
				} else if (migration.State === 'aborting') {
					i = 6;
				} else {
					$assert.Assert(migration.State === 'aborted');
					i = 7;
				}

				if (stateInfoArray[i].count === 0) {
					// We haven't seen this state before. Set the index for the position in the UI.
					stateInfoArray[i].index = index;
					index++;
				}
				stateInfoArray[i].count++;
			}

			stateInfoArray = stateInfoArray.filter((info) => 0 < info.count);

			stateInfoArray.sort((a: MigrationStateEntry, b: MigrationStateEntry) => {
				return a.index - b.index;
			});

			stateInfoArraysOfWorkspace[workspaceUuid] = stateInfoArray;
		}

		const workspacesMap: { [key: string]: Workspace } = {};
		const groupsSet = new Set<string>();

		for (const workspace of this.workspaces || []) {
			workspacesMap[workspace.UUID] = workspace;
			const migrations: Migration[] | undefined = migrationsOfWorkspace[workspace.UUID];
			const stateInfoArray: MigrationStateEntry[] | undefined = stateInfoArraysOfWorkspace[workspace.UUID];

			let item = this.itemsMap[workspace.UUID];

			// Skip if the item is already up-to-date. We need to check the length to correctly handle deleted migrations.
			if (item && (item.latestUpdateDate !== latestUpdateDateOfWorkspace[workspace.UUID] || migrations?.length !== item.Migrations?.length)) {
				item.Migrations = migrations;
				item.Migration = migrations?.[0];
				item.stateInfoArray = stateInfoArray;
				item.latestUpdateDate = latestUpdateDateOfWorkspace[workspace.UUID];
			} else if (!item) {
				let group = this.$tsStore.migrations.migrationGroupByErpId?.[workspace.ErpId || '?'] ?? null;
				if (!group && workspace.BaseSelected) {
					// "si" = "selected internal workspace"
					// "s"  = "selected customer Base workspace"
					group = (workspace.OwnerEmailType === 'faro') ? 'si' : 's';
				}
				item = new MigrationItem(workspace, group, migrations, stateInfoArray);
				item.latestUpdateDate = latestUpdateDateOfWorkspace[workspace.UUID];
				this.items.push(item);
				this.itemsMap[workspace.UUID] = item;
			}

			if (item.Group) {
				groupsSet.add(item.Group);
			}
		}

		this.groups = Array.from(groupsSet);
		this.groups.sort();
		if (this.groups.length) {
			this.groupForEstDurations = this.groups[0];
		}

		if (wasItemsEmpty) {
			// Default sort order: 1) Latest migration StartDate/ScheduledDate/CreationDate, 2) Group, 2) Workspace name.
			this.items.sort((a: MigrationItem, b: MigrationItem) => {
				const aCD = a.Migrations?.[0]?.startOrScheduledOrCreationDate || '';
				const bCD = b.Migrations?.[0]?.startOrScheduledOrCreationDate || '';
				if (aCD !== bCD) {
					return bCD.localeCompare(aCD);
				} else if (a.Group !== b.Group) {
					// 'z' comes after '9' and 'Z', so 'zz' should always be after the last group.
					return (a.GroupSortable || 'zz').localeCompare(b.GroupSortable || 'zz');
				} else {
					return a.Workspace.Name.localeCompare(b.Workspace.Name);
				}
			});
		}

		// Get the estimated durations for all pending and running migrations.
		const promisesDuration: Promise<void>[] = [];
		const limiterDuration = new Bottleneck({ maxConcurrent: 8 });

		for (const migration of allMigrations) {
			if (!(migration.State === 'pending' || migration.State === 'running') || this.durationMap[migration.Workspace]) {
				continue;
			}
			const workspace = workspacesMap[migration.Workspace];
			// We can ignore the special case that there are multiple pending/running migrations for the same
			// workspace. In that case, we simply make multiple requests that overwrite the same value.

			const promiseDuration = limiterDuration.schedule(async () => {
				await this.calcEstimatedDurations(workspace);
			});
			promisesDuration.push(promiseDuration);
		}

		await Promise.allSettled(promisesDuration);
	}

	/**
	 * Called by getData to get the information about currently running migrations and their projects and tasks.
	 * @author OK
	 * @param workspacesMap Workspace UUID to workspace map.
	 */
	protected async getRunningMigrationsStats(): Promise<void> {
		let nRunning = 0;
		let nProjectsTotal = 0;
		let nProjectsComplete = 0;
		let nProjectsError = 0;
		let taskResultMap: { [key: string]: TaskResult } = {};
		let nTasksTotal = 0;
		let nTasksComplete = 0;
		let nTasksError = 0;

		const workspacesMap: { [key: string]: Workspace } = {};
		if (!this.workspaces) {
			return;
		}
		for (const workspace of this.workspaces) {
			workspacesMap[workspace.UUID] = workspace;
		}

		const migrations = this.$tsStore.migrations.itemsList;

		const runningMigrations: Migration[] = [];
		const promises: Promise<Migration>[] = [];
		const limiter = new Bottleneck({ maxConcurrent: 8 });

		for (const migration of migrations) {
			if (migration.State === 'running') {
				nRunning++;

				const workspace = workspacesMap[migration.Workspace];
				$assert.Assert(workspace);
				if (workspace) {
					const promise = limiter.schedule(async () => {
						// throws HttpError
						return await this.$tsStore.migrations.getMigration({
							region: workspace.Region as WebshareRegion,
							uuid: migration.UUID,
						});
					});
					promises.push(promise);
				}
			}
		}
		const results = await Promise.allSettled(promises);

		let error: any;
		for (const resultRunning of results) {
			if (resultRunning.status === 'fulfilled') {
				runningMigrations.push(resultRunning.value);
			} else if (resultRunning.status === 'rejected') {
				error = resultRunning.reason;
				break;
			}
		}
		if (error) {
			throw error;
		}

		for (const runningMigration of runningMigrations) {
			// save some local migration data for showing the warning flag
			let localFailed = 0;
			let localSucceeded = 0;
			let localExpected = 0;
			let localProjectError = 0;

			for (const projectUuid in runningMigration.Steps.Step7.ProjectContentResults) {
				const result = runningMigration.Steps.Step7.ProjectContentResults[projectUuid];
				nProjectsTotal++;
				if (result.Result === 'complete') {
					nProjectsComplete++;
				} else if (result.Result === 'error') {
					nProjectsError++;
					localProjectError++;
				}

				const tasks = result.Tasks ?? [];
				for (const task of tasks) {
					if (!taskResultMap[task.taskType]) {
						taskResultMap[task.taskType] = {
							total: 0,
							complete: 0,
							error: 0,
						};
					}

					// We must correctly handle retried tasks.
					const expected = task.taskStates.Expected ?? 0;
					const succeeded = task.taskStates.Succeeded ?? 0;
					const failedRaw = (task.taskStates.Aborted ?? 0) + (task.taskStates.Failed ?? 0);

					// "succeeded > expected" is an edge case that should normally not happen.
					const failed = (succeeded >= expected) ? 0 : failedRaw;

					localFailed += failed;
					localSucceeded += succeeded;
					localExpected += expected;

					nTasksTotal += expected;
					taskResultMap[task.taskType].total += expected;
					nTasksComplete += succeeded;
					taskResultMap[task.taskType].complete += succeeded;
					nTasksError += failed;
					taskResultMap[task.taskType].error += failed;
				}
			}

			// Update the error flag for the workspace.
			const listItem = this.itemsMap[runningMigration.Workspace];
			if (listItem) {
				// Set the error flag if there are any failed tasks or if there are more failed tasks than succeeded tasks.
				// May fail/overwrite if there are multiple running migrations of the same workspace.
				listItem.hasRunningMigrationWithErrors = localProjectError > 0 || (localFailed > 0 && (localSucceeded < localExpected));
			}
		}

		this.nRunning = nRunning;
		this.nProjectsTotal = nProjectsTotal;
		this.nProjectsComplete = nProjectsComplete;
		this.nProjectsError = nProjectsError;
		this.taskResultMap = taskResultMap;
		this.nTasksTotal = nTasksTotal;
		this.nTasksComplete = nTasksComplete;
		this.nTasksError = nTasksError;
	}

	/**
	 * Gets all the data that is displayed on first page load.
	 * @author OK
	 */
	protected async getInitialData(): Promise<void> {
		// Must be called before getMigrations(), for MigrationItem constructor.
		// Having the groups is not essential for showing the page. -> Continue on errors.
		const getMigrationGroups = this.$tsStore.migrations.getMigrationGroups().catch((error) => {
			console.error('Error getting migration groups:', error);
			this.$faroComponents.$emit('show-error', { error, title: 'Error getting migration groups!' });
		});
		await Promise.all([
			getMigrationGroups,
			this.getWorkspaces(),
		]);

		await this.getMigrations();
		// The information about running migrations isn't essential, so catch all errors.
		try {
			await this.getRunningMigrationsStats();
		} catch (error) {
			this.$faroComponents.$emit('show-error', { error, title: 'Error getting running migrations for statistics!' });
		}
	}

	public async mounted() {
		this.loading = true;
		try {
			this.$faroLoading.start();

			const dataPromise = this.getInitialData();
			const ssoPromise = this.getSsoData();
			await Promise.all([dataPromise, ssoPromise]);

			this.pollingInterval = setInterval(async () => {
				// document.hidden: Save resources when browser tab is hidden.
				// $route.name:     Avoid problems on MigrationPage, which does its own polling.
				if (document.hidden || this.$route.name !== 'MigrationDashboardPage') {
					return;
				}
				await this.pollMigrations(false);
			}, 20_000);
		} catch (error) {
			console.error(error);

			this.error = true;
			this.errorMsg = getErrorMessage(error);
		} finally {
			this.$tsStore.pages.setFinishedPageLoading(true);
			if (this.$tsStore.pages.finishedMainLoading) {
				this.$faroLoading.stop();
			}
			this.loading = false;
		}

		// Optional feature, therefore not included in loading spinner:
		// Check which workspaces have stats available, to avoid showing an error message when the user tries to view them.
		if (!this.workspacesWithStats) {
			const statsObjs: StatsAggregatedMap = await this.$tsStore.migrations.getAggregatedStatsGlobal();
			this.workspacesWithStats = {};
			for (const workspaceUuid in statsObjs) {
				this.workspacesWithStats[workspaceUuid] = true;
			}

			for (const item of this.items) {
				if (statsObjs[item.Workspace.UUID] && statsObjs[item.Workspace.UUID].Projects) {
					item.NumProjects = statsObjs[item.Workspace.UUID].Projects;
				}
			}
		}

		const analyticsService = new AnalyticsService();
		const updateMap = await analyticsService.getLastUpdates();
		for (const item of this.items) {
			if (updateMap[item.Workspace.UUID] && !updateMap[item.Workspace.UUID].startsWith('1970')) {
				item.LastUpdate = updateMap[item.Workspace.UUID];
			}
		}
	}

	beforeDestroy() {
		if (this.pollingInterval) {
			clearInterval(this.pollingInterval);
			this.pollingInterval = null;
		}
	}

	public async pollMigrations(interactive: boolean): Promise<void> {
		if (this.isPolling) {
			return;
		}
		this.isPolling = true;
		if (interactive) {
			this.$faroLoading.start();
		}

		await this.$tsStore.migrationWorkspaces.updatePropertiesFromAuthZ().catch((error) => {
			this.$faroComponents.$emit('show-error', { error, title: 'Error polling workspaces from AuthZ!' });
		});
		await this.getMigrations().catch((error) => {
			this.$faroComponents.$emit('show-error', { error, title: 'Error polling migrations!' });
		});
		await this.getRunningMigrationsStats().catch((error) => {
			this.$faroComponents.$emit('show-error', { error, title: 'Error polling running migrations for statistics!' });
		});

		this.isPolling = false;
		if (interactive) {
			this.$faroLoading.stop();
		}
	}
}
